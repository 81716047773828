.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #282c34;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: monospace;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.list {
  list-style-type: none;
}

ul.list li {
  display: inline;
  padding-right: 20px;
}

.list a {
  text-decoration: underline;
  color: inherit;
}

ul.header {
  background-color: #111;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #181A1B;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

.container {
  width: 70%;
}

.hidden {
  display: none;
}
.active {
  background-color: #0099FF;
}

.iframe {
  background-color: white;
}